import { default as indexVxII3jub38Meta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/about/index.vue?macro=true";
import { default as _91slug_93uXQ32OKC5qMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/authors/[slug].vue?macro=true";
import { default as indexi6Ry1Kyiq2Meta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/authors/index.vue?macro=true";
import { default as _91slug_93x4rgAQmwxDMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/collections/[slug].vue?macro=true";
import { default as indexYaz9XoVTUiMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/collections/index.vue?macro=true";
import { default as indexODJ3rwEsPdMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/destination/[paragraph]/index.vue?macro=true";
import { default as _91slug_93G73Zqt1T3yMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/destination/[paragraph]/region_article/[slug].vue?macro=true";
import { default as _91slug_93A5yJeFaM43Meta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/destination/[paragraph]/region_heraldic/[slug].vue?macro=true";
import { default as _91slug_9307KkIMfCgTMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/destination/[paragraph]/region_route/[slug].vue?macro=true";
import { default as _91paragraph_93e6nSzHsQqWMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/destination/[paragraph].vue?macro=true";
import { default as indexda8oXV4cWGMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/destination/index.vue?macro=true";
import { default as destinationC0XfYN9DhiMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/destination.vue?macro=true";
import { default as eventsUqOmfXoS98Meta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/events.vue?macro=true";
import { default as gpt_45chat9EG1IVnlaEMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/gpt-chat.vue?macro=true";
import { default as indexpfoZbKxjjuMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/index.vue?macro=true";
import { default as libraryLuD2qmfYkrMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/library.vue?macro=true";
import { default as _91slug_93U08cY0hpNFMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/listen/courses/[slug].vue?macro=true";
import { default as indexlSLwcTYUTXMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/listen/courses/index.vue?macro=true";
import { default as _91slug_93zOochBIMcDMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/listen/lectures/[slug].vue?macro=true";
import { default as indexBQyaqiECWVMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/listen/lectures/index.vue?macro=true";
import { default as _91slug_93ttLa1qfbt3Meta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/magazine/article/[slug].vue?macro=true";
import { default as _91slug_93efe8OKHVxtMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/magazine/release/[slug].vue?macro=true";
import { default as myhistoryqZ87QKdYilMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/myhistory.vue?macro=true";
import { default as bookmarksc2kV306gsvMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/profile/bookmarks.vue?macro=true";
import { default as indexsKcdw4TL0nMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/profile/index.vue?macro=true";
import { default as results8eFEBd2ae4Meta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/profile/results.vue?macro=true";
import { default as indexCD73EMNLhMMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/projects/index.vue?macro=true";
import { default as _91slug_93sXGU2zqqKgMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/read/articles/[slug].vue?macro=true";
import { default as indexjIA5mfY8PuMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/read/articles/index.vue?macro=true";
import { default as _91slug_939JCRI3GebRMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/read/biographies/[slug].vue?macro=true";
import { default as indexTnxHbRJsK8Meta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/read/biographies/index.vue?macro=true";
import { default as _91document_93B1QJ3D004FMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/read/documents/[slug]/[document].vue?macro=true";
import { default as indexVTQYHF8BJuMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/read/documents/[slug]/index.vue?macro=true";
import { default as index23RRDGq3G5Meta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/read/documents/index.vue?macro=true";
import { default as _91slug_93xiwLGoIymgMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/read/monuments/[slug].vue?macro=true";
import { default as indexeTfUVYEl06Meta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/read/monuments/index.vue?macro=true";
import { default as _91slug_93QGuJIKJFWCMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/read/news/[slug].vue?macro=true";
import { default as indexdQ8WJwCnSIMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/read/news/index.vue?macro=true";
import { default as _91slug_93IYk7tybjEJMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/read/science/[slug].vue?macro=true";
import { default as indexMK87oyGevjMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/read/science/index.vue?macro=true";
import { default as search09rmcPtUaoMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/search.vue?macro=true";
import { default as summerL21qbgVwTRMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/summer.vue?macro=true";
import { default as _91slug_93nsjB0WPCV4Meta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/tags/[slug].vue?macro=true";
import { default as _91chapter_93v1zL0mBbraMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/teacher/[parent]/[category]/[chapter].vue?macro=true";
import { default as _91slug_93rrVRzSKog7Meta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/teacher/[parent]/[category]/article/[slug].vue?macro=true";
import { default as _91slug_93HSNamcTFJxMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/teacher/[parent]/[category]/audiolecture/[slug].vue?macro=true";
import { default as _91slug_93rqIKNiD3CWMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/teacher/[parent]/[category]/chapter/[slug].vue?macro=true";
import { default as indexE9YLdt8x6VMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/teacher/[parent]/[category]/index.vue?macro=true";
import { default as _91slug_934lncBukHOGMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/teacher/[parent]/[category]/test/[slug].vue?macro=true";
import { default as _91slug_93xy3kwQS7ghMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/teacher/[parent]/[category]/videolecture/[slug].vue?macro=true";
import { default as _91category_93qEKjRKkI05Meta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/teacher/[parent]/[category].vue?macro=true";
import { default as indextq5ECePyJkMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/teacher/[parent]/index.vue?macro=true";
import { default as _91parent_93mXzWfAOJAMMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/teacher/[parent].vue?macro=true";
import { default as _91slug_933A22ZprVVLMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/teacher/glossary/[slug].vue?macro=true";
import { default as indexkbuX2ieCMcMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/teacher/index.vue?macro=true";
import { default as teacherqJ8Cq87EwTMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/teacher.vue?macro=true";
import { default as _91slug_93GSucaFb6H1Meta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/tests/[slug].vue?macro=true";
import { default as indexZ5TEAxpFxOMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/tests/index.vue?macro=true";
import { default as indexkbBaMlq22iMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/timeline/index.vue?macro=true";
import { default as victory_45day20dInAkorRMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/victory-day.vue?macro=true";
import { default as _91slug_9355oF2vJX0uMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/watch/courses/[slug].vue?macro=true";
import { default as indexSUFlv6LWtkMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/watch/courses/index.vue?macro=true";
import { default as _91slug_93He910FDvFmMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/watch/films/[slug].vue?macro=true";
import { default as indexbaYp0GhQoYMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/watch/films/index.vue?macro=true";
import { default as _91slug_93iAEUFo8vdcMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/watch/lectures/[slug].vue?macro=true";
import { default as indexUnOCXbEeKDMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/watch/lectures/index.vue?macro=true";
import { default as indexlp51MtIlPqMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/watch/memdates/index.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/about/index.vue")
  },
  {
    name: "authors-slug",
    path: "/authors/:slug()",
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/authors/[slug].vue")
  },
  {
    name: "authors",
    path: "/authors",
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/authors/index.vue")
  },
  {
    name: "collections-slug",
    path: "/collections/:slug()",
    meta: _91slug_93x4rgAQmwxDMeta || {},
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/collections/[slug].vue")
  },
  {
    name: "collections",
    path: "/collections",
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/collections/index.vue")
  },
  {
    name: destinationC0XfYN9DhiMeta?.name,
    path: "/destination",
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/destination.vue"),
    children: [
  {
    name: _91paragraph_93e6nSzHsQqWMeta?.name,
    path: ":paragraph()",
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/destination/[paragraph].vue"),
    children: [
  {
    name: "destination-paragraph",
    path: "",
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/destination/[paragraph]/index.vue")
  },
  {
    name: "destination-paragraph-region_article-slug",
    path: "region_article/:slug()",
    meta: _91slug_93G73Zqt1T3yMeta || {},
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/destination/[paragraph]/region_article/[slug].vue")
  },
  {
    name: "destination-paragraph-region_heraldic-slug",
    path: "region_heraldic/:slug()",
    meta: _91slug_93A5yJeFaM43Meta || {},
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/destination/[paragraph]/region_heraldic/[slug].vue")
  },
  {
    name: "destination-paragraph-region_route-slug",
    path: "region_route/:slug()",
    meta: _91slug_9307KkIMfCgTMeta || {},
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/destination/[paragraph]/region_route/[slug].vue")
  }
]
  },
  {
    name: "destination",
    path: "",
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/destination/index.vue")
  }
]
  },
  {
    name: "events",
    path: "/events",
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/events.vue")
  },
  {
    name: "gpt-chat",
    path: "/gpt-chat",
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/gpt-chat.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexpfoZbKxjjuMeta || {},
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/index.vue")
  },
  {
    name: "library",
    path: "/library",
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/library.vue")
  },
  {
    name: "listen-courses-slug",
    path: "/listen/courses/:slug()",
    meta: _91slug_93U08cY0hpNFMeta || {},
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/listen/courses/[slug].vue")
  },
  {
    name: "listen-courses",
    path: "/listen/courses",
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/listen/courses/index.vue")
  },
  {
    name: "listen-lectures-slug",
    path: "/listen/lectures/:slug()",
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/listen/lectures/[slug].vue")
  },
  {
    name: "listen-lectures",
    path: "/listen/lectures",
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/listen/lectures/index.vue")
  },
  {
    name: "magazine-article-slug",
    path: "/magazine/article/:slug()",
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/magazine/article/[slug].vue")
  },
  {
    name: "magazine-release-slug",
    path: "/magazine/release/:slug()",
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/magazine/release/[slug].vue")
  },
  {
    name: "myhistory",
    path: "/myhistory",
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/myhistory.vue")
  },
  {
    name: "profile-bookmarks",
    path: "/profile/bookmarks",
    meta: bookmarksc2kV306gsvMeta || {},
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/profile/bookmarks.vue")
  },
  {
    name: "profile",
    path: "/profile",
    meta: indexsKcdw4TL0nMeta || {},
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/profile/index.vue")
  },
  {
    name: "profile-results",
    path: "/profile/results",
    meta: results8eFEBd2ae4Meta || {},
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/profile/results.vue")
  },
  {
    name: "projects",
    path: "/projects",
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/projects/index.vue")
  },
  {
    name: "read-articles-slug",
    path: "/read/articles/:slug()",
    meta: _91slug_93sXGU2zqqKgMeta || {},
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/read/articles/[slug].vue")
  },
  {
    name: "read-articles",
    path: "/read/articles",
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/read/articles/index.vue")
  },
  {
    name: "read-biographies-slug",
    path: "/read/biographies/:slug()",
    meta: _91slug_939JCRI3GebRMeta || {},
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/read/biographies/[slug].vue")
  },
  {
    name: "read-biographies",
    path: "/read/biographies",
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/read/biographies/index.vue")
  },
  {
    name: "read-documents-slug-document",
    path: "/read/documents/:slug()/:document()",
    meta: _91document_93B1QJ3D004FMeta || {},
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/read/documents/[slug]/[document].vue")
  },
  {
    name: "read-documents-slug",
    path: "/read/documents/:slug()",
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/read/documents/[slug]/index.vue")
  },
  {
    name: "read-documents",
    path: "/read/documents",
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/read/documents/index.vue")
  },
  {
    name: "read-monuments-slug",
    path: "/read/monuments/:slug()",
    meta: _91slug_93xiwLGoIymgMeta || {},
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/read/monuments/[slug].vue")
  },
  {
    name: "read-monuments",
    path: "/read/monuments",
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/read/monuments/index.vue")
  },
  {
    name: "read-news-slug",
    path: "/read/news/:slug()",
    meta: _91slug_93QGuJIKJFWCMeta || {},
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/read/news/[slug].vue")
  },
  {
    name: "read-news",
    path: "/read/news",
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/read/news/index.vue")
  },
  {
    name: "read-science-slug",
    path: "/read/science/:slug()",
    meta: _91slug_93IYk7tybjEJMeta || {},
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/read/science/[slug].vue")
  },
  {
    name: "read-science",
    path: "/read/science",
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/read/science/index.vue")
  },
  {
    name: "search",
    path: "/search",
    meta: search09rmcPtUaoMeta || {},
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/search.vue")
  },
  {
    name: "summer",
    path: "/summer",
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/summer.vue")
  },
  {
    name: "tags-slug",
    path: "/tags/:slug()",
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/tags/[slug].vue")
  },
  {
    name: teacherqJ8Cq87EwTMeta?.name,
    path: "/teacher",
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/teacher.vue"),
    children: [
  {
    name: _91parent_93mXzWfAOJAMMeta?.name,
    path: ":parent()",
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/teacher/[parent].vue"),
    children: [
  {
    name: _91category_93qEKjRKkI05Meta?.name,
    path: ":category()",
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/teacher/[parent]/[category].vue"),
    children: [
  {
    name: "teacher-parent-category-chapter",
    path: ":chapter()",
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/teacher/[parent]/[category]/[chapter].vue")
  },
  {
    name: "teacher-parent-category-article-slug",
    path: "article/:slug()",
    meta: _91slug_93rrVRzSKog7Meta || {},
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/teacher/[parent]/[category]/article/[slug].vue")
  },
  {
    name: "teacher-parent-category-audiolecture-slug",
    path: "audiolecture/:slug()",
    meta: _91slug_93HSNamcTFJxMeta || {},
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/teacher/[parent]/[category]/audiolecture/[slug].vue")
  },
  {
    name: "teacher-parent-category-chapter-slug",
    path: "chapter/:slug()",
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/teacher/[parent]/[category]/chapter/[slug].vue")
  },
  {
    name: "teacher-parent-category",
    path: "",
    meta: indexE9YLdt8x6VMeta || {},
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/teacher/[parent]/[category]/index.vue")
  },
  {
    name: "teacher-parent-category-test-slug",
    path: "test/:slug()",
    meta: _91slug_934lncBukHOGMeta || {},
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/teacher/[parent]/[category]/test/[slug].vue")
  },
  {
    name: "teacher-parent-category-videolecture-slug",
    path: "videolecture/:slug()",
    meta: _91slug_93xy3kwQS7ghMeta || {},
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/teacher/[parent]/[category]/videolecture/[slug].vue")
  }
]
  },
  {
    name: "teacher-parent",
    path: "",
    meta: indextq5ECePyJkMeta || {},
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/teacher/[parent]/index.vue")
  }
]
  },
  {
    name: "teacher-glossary-slug",
    path: "glossary/:slug()",
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/teacher/glossary/[slug].vue")
  },
  {
    name: "teacher",
    path: "",
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/teacher/index.vue")
  }
]
  },
  {
    name: "tests-slug",
    path: "/tests/:slug()",
    meta: _91slug_93GSucaFb6H1Meta || {},
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/tests/[slug].vue")
  },
  {
    name: "tests",
    path: "/tests",
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/tests/index.vue")
  },
  {
    name: "timeline",
    path: "/timeline",
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/timeline/index.vue")
  },
  {
    name: "victory-day",
    path: "/victory-day",
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/victory-day.vue")
  },
  {
    name: "watch-courses-slug",
    path: "/watch/courses/:slug()",
    meta: _91slug_9355oF2vJX0uMeta || {},
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/watch/courses/[slug].vue")
  },
  {
    name: "watch-courses",
    path: "/watch/courses",
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/watch/courses/index.vue")
  },
  {
    name: "watch-films-slug",
    path: "/watch/films/:slug()",
    meta: _91slug_93He910FDvFmMeta || {},
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/watch/films/[slug].vue")
  },
  {
    name: "watch-films",
    path: "/watch/films",
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/watch/films/index.vue")
  },
  {
    name: "watch-lectures-slug",
    path: "/watch/lectures/:slug()",
    meta: _91slug_93iAEUFo8vdcMeta || {},
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/watch/lectures/[slug].vue")
  },
  {
    name: "watch-lectures",
    path: "/watch/lectures",
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/watch/lectures/index.vue")
  },
  {
    name: "watch-memdates",
    path: "/watch/memdates",
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/watch/memdates/index.vue")
  }
]